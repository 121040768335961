import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import ImageUploader from '../componentes/ImageUploader';

const MiPerfil = () => {
  return (
    <Box sx={{ width: '100%' }}>
      {/* Título */}
      <Typography variant="h3" component="h1" align="center" sx={{ my: 4 }}>
        Mi perfil
      </Typography>

      {/* Contenedor de 3 columnas */}
      <Grid container spacing={3} sx={{ px: 3 }}>
        {/* Primera columna */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              p: 3,
              border: '1px solid #ccc',
              borderRadius: 2,
              boxShadow: 2,
              backgroundColor: '#fff',
              minHeight: '200px', // Asegura que todas las columnas tengan al menos esta altura
            }}
          >

            <ImageUploader />
          </Box>
        </Grid>

        {/* Segunda columna */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              p: 3,
              border: '1px solid #ccc',
              borderRadius: 2,
              boxShadow: 2,
              backgroundColor: '#f5f5f5',
              minHeight: '200px',
            }}
          >
            <Typography color="textSecondary" align="center">
              Aquí va contenido adicional
            </Typography>
          </Box>
        </Grid>

        {/* Tercera columna */}
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              p: 3,
              border: '1px solid #ccc',
              borderRadius: 2,
              boxShadow: 2,
              backgroundColor: '#f5f5f5',
              minHeight: '200px',
            }}
          >
            <Typography color="textSecondary" align="center">
              Aquí va contenido adicional
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MiPerfil;
