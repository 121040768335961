import { createTheme } from '@mui/material/styles';
import zebraPattern from './recursos/fondo_cebra.png'
const tema = createTheme({
  palette: {
    primary: {
      main: '#ED1C24', // Rojo para enlaces, botones, etc.
    },
    secondary: {
      main: '#FFA94F', // Naranja para algunos fondos y botones
      dark: '#FF8C00', // Naranja más oscuro para hover
    },
    background: {
      default: '#F4F6F8', // Fondo general de la página
      paper: '#FFFFFF',   // Fondo blanco para componentes como tarjetas
    },
    text: {
      primary: '#000000', // Texto negro por defecto
      secondary: '#BDBDBD', // Texto blanco para fondo oscuro o resaltado
    },
  },
  typography: {
    fontFamily: 'Verdana, Arial, Helvetica, sans-serif', // Tipografía del CSS original
    h1: {
      fontFamily: 'Georgia, Times New Roman, Times, serif', // Título h1 con fuente serif
    },
    button: {
      fontSize: '14px',
      fontWeight: 'bold', // Botones más destacados
      textTransform: 'none', // No convertir los botones en mayúsculas
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFA94F', // Naranja para los botones
          color: '#FFFFFF', // Texto blanco en los botones
          border: '1px solid #cc6600', // Bordes de color naranja
          padding: '10px 20px', // Padding ajustado a la estética
          borderRadius: '5px', // Bordes redondeados
          '&:hover': {
            backgroundColor: '#FFB57B', // Naranja más claro cuando se pasa el ratón
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#000000', // Color negro para los labels
          '&.Mui-focused': {
            color: '#000000', // Color negro cuando el campo está enfocado
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF', // Fondo blanco para los papeles
        },
      },
    },
    // MuiCard: {
    //   styleOverrides: {
    //     root: {
    //       backgroundImage: `url(${zebraPattern})`, // Fondo PNG para tarjetas
    //       backgroundRepeat: 'no-repeat',
    //       backgroundSize: 'cover', // Ajusta el tamaño
    //       backgroundPosition: 'center', // Centrado
    //       padding: '20px',
    //       borderRadius: '8px',
    //     },
    //   },
    // },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: `
            linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), 
            url(${zebraPattern})
          `, // Añade un degradado semitransparente encima de la imagen
          backgroundRepeat: 'no-repeat', // Evita que el fondo se repita
          backgroundSize: 'cover', // Ajusta el tamaño para cubrir el área visible
          backgroundPosition: 'center', // Centra la imagen
          backgroundAttachment: 'fixed', // Fija la imagen al hacer scroll
          backgroundColor: '#F4F6F8', // Color de respaldo si no se carga la imagen
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        },
      },
    },
    
    
    
  },
  // Agregar estilo global para el fondo de cebra usando gradientes
  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       body: {
  //         background: 'repeating-linear-gradient(45deg, #000000 0px, #000000 5px, #FFFFFF 5px, #FFFFFF 10px)', // Patrón de líneas de cebra
  //         backgroundSize: '100% 100%', // Asegurar que el fondo cubra toda la página
  //         backgroundAttachment: 'fixed', // Asegurar que el fondo no se mueva cuando se haga scroll
  //       },
  //     },
  //   },
  // },
});

export default tema;
