import React, {useContext} from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  CardMedia,
  Grid,
  IconButton,
} from "@mui/material";
import {AuthContext} from "../../servicios/authContext";
import authService from "../../servicios/authService";
import LockIcon from '@mui/icons-material/Lock';
import { useNavigate } from "react-router-dom";
const TarjetaUsuario = ({ usuario, onUsuarioClick, tarjetasPorFila }) => {
    const navigation=useNavigate()
    const handleLockClick = (event) => {
        event.stopPropagation();
        // Aquí puedes manejar lo que debe suceder cuando el SuperAdmin haga clic en el ícono de la llave.
        console.log(`SuperAdmin hizo clic en la llave para el usuario ${usuario.user.name}`);

        // return
        authService.autologin(usuario.user.id).then((r) => {
          navigation('/')
          // return
          // Aquí puedes redirigir o hacer cualquier acción después de la suplantación
          window.location.reload(); // Recargar la página para aplicar el nuevo token
        });
      };

const  { authData, setAuthData, isLoading }=useContext(AuthContext)


  return (
    <Grid item xs={12} sm={6} md={6} lg={tarjetasPorFila} key={usuario.id}>
      <Card onClick={() => onUsuarioClick(usuario)} sx={{ display: "flex", flexDirection: "column", minHeight: "300px", position: "relative" }}>
      {authData.rol === 'SuperAdmin' && (
           <IconButton
           onClick={handleLockClick}
           sx={{
             position: "relative",
             backgroundColor: "white",
             borderRadius: "50%",
             padding: 0.5,
             marginLeft: 0.5, // Espacio entre el avatar y la llave
           }}
         >
           <LockIcon />
         </IconButton>
        )}
        <Avatar
          alt={usuario.user.name}
          src={usuario.user ? usuario.user.imagenperfil : usuario.imagenperfil}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            width: 50,
            height: 50,
          }}
        />
        <CardContent>
          <Typography variant="h6" sx={{ fontWeight: "bold", marginRight: 5 }}>
            {usuario.user.name}
          </Typography>
          <Typography variant="body2" color="primary.main" gutterBottom>
            {usuario.user ? usuario.user.email : usuario.email}
          </Typography>
          <Typography variant="body2" color="secondary">
            Rol: {usuario.user.rol}
          </Typography>
        </CardContent>
        {usuario.equipos && usuario.equipos.length > 0 && usuario.equipos[0].terreno_juego && (
          <CardMedia
            component="img"
            image={usuario.equipos[0].terreno_juego.imagenterrenojuego}
            alt="Terreno de Juego"
            sx={{
              width: "90%",
              margin: "10px auto",
              borderRadius: "8px",
              objectFit: "cover",
            }}
          />
        )}
      </Card>
    </Grid>
  );
};


export default TarjetaUsuario;
