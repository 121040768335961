import React, { useEffect } from 'react';
import { Container, Box, Typography } from '@mui/material';
import MostrarUsuarios from '../componentes/Usuarios/MostrarUsuarios';


const Clubs = ({ titulo }) => {
  useEffect(() => {
    document.title = titulo; // Cambia el título de la pestaña del navegador
  }, [titulo]);

  return (
    <Box>
      {/* <MyNavbar /> */}

      <Container
        maxWidth="xl"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'center',
          alignItems: 'center',
          width: "100%", // Esto asegura que el contenedor ocupe todo el ancho disponible
          maxWidth: "100%", // Esto también asegura que no haya un límite de ancho 
          minHeight: '100vh',
          textAlign: 'center',
        }}
      >
        <MostrarUsuarios rol='Club' anchocompleto={true}/>
      </Container>
    </Box>
  );
};

export default Clubs;

