import React, { useEffect, useState } from "react";
import {
  getDeportes,
  getModalidades,
  crearClub,
  actualizarClub,
} from "../../servicios/llamadasApi";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Typography,
  CircularProgress,
  IconButton,
  InputAdornment,
  Switch,
  FormControlLabel,
} from "@mui/material";
import {
  CleaningServices,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import CargandoDatos from "../../Auxiliares/CargandoDatos";
import Swal from "sweetalert2";

const NuevoUsuario = ({
  rol,
  usuarioedicion = null,
  setRefrescar,
  refrescar,
}) => {
  const [modoEdicion, setModoEdicion] = useState(!!usuarioedicion);

  const [deportes, setDeportes] = useState([]);
  const [modalidades, setModalidades] = useState([]);
  const [selectedDeporte, setSelectedDeporte] = useState(1);
  const [selectedModalidad, setSelectedModalidad] = useState("");
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formError, setFormError] = useState({}); // Estado para validaciones del formulario

  useEffect(() => {
    if (modoEdicion && usuarioedicion) {
      console.log(
        "%csrccomponentesUsuariosNuevoUsuario.js:45 usuarioedicion",
        "color: #007acc;",
        usuarioedicion
      );
      setNombre(usuarioedicion.user?.name || "");
      setEmail(usuarioedicion.user?.email || "");
      setSelectedDeporte(usuarioedicion.deporte_id || "");
      setSelectedModalidad(usuarioedicion.modalidad_id || "");
    }
  }, [usuarioedicion, modoEdicion]);

  useEffect(() => {
    setModoEdicion(!!usuarioedicion);
  }, [usuarioedicion]);

  const handleModoEdicionChange = (event) => {
    const isEditing = event.target.checked;
    setModoEdicion(isEditing);
    if (!isEditing) {
      setNombre("");
      setEmail("");
      setSelectedDeporte(1);
      setSelectedModalidad("");
      setPassword("");
      setConfirmPassword("");
      usuarioedicion = null; // Resetear usuarioedicion explícitamente
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [deportesResponse, modalidadesResponse] = await Promise.all([
          getDeportes(),
          getModalidades(),
        ]);
        setDeportes(deportesResponse || []);
        setModalidades(modalidadesResponse || []);
        setError(null);
      } catch (err) {
        setError("Error al cargar deportes o modalidades.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleDeporteChange = (event) => {
    setSelectedDeporte(event.target.value);
  };

  const handleModalidadChange = (event) => {
    setSelectedModalidad(event.target.value);
  };

  const validateForm = (ignorarpassword = false) => {
    const errors = {};
    if (selectedDeporte != 1) errors.deporte = "El deporte es obligatorio";
    if (selectedModalidad === "")
      errors.modalidad = "La modalidad es obligatoria";
    if (!nombre.trim()) errors.nombre = "El nombre es obligatorio";
    if (!email.trim()) {
      errors.email = "El email es obligatorio";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "El email no es válido";
    }
    if (!ignorarpassword) {
      if (!password.trim()) errors.password = "La contraseña es obligatoria";
      if (!confirmPassword.trim())
        errors.confirmPassword = "Confirma la contraseña";
      if (password && confirmPassword && password !== confirmPassword) {
        errors.confirmPassword = "Las contraseñas no coinciden";
      }
    }
    setFormError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;

    const nuevoUsuario = {
      nombre,
      email,
      password,
      deporte_id: selectedDeporte,
      modalidad_id: selectedModalidad,
      rol,
    };

    console.log("Datos enviados:", nuevoUsuario);
    // Aquí se podría llamar a una API para guardar el usuario
    // return
    enviarDatos(nuevoUsuario);
  };

  const handleUpdate = async () => {
    if (!validateForm(true)) return;
    const usuarioActualizado = {
      ...usuarioedicion,
      nombre,
      email,
      rol,
      password,
      modalidad_id: selectedModalidad,
    };
    console.log("Actualizando usuario:", usuarioActualizado);
    // Lógica para actualizar el usuario
    setLoading(true);
    try {
      const response = await actualizarClub(usuarioActualizado);
      console.log(response);
      Swal.fire({
        icon: "success",
        title: "Correcto",
        text: `${response.message}`,
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err || "Autenticación fallida",
      });
      // setError('Error al crear usuario.', err);
    } finally {
      setLoading(false);
      setRefrescar(!refrescar);
    }
  };
  const enviarDatos = async (nuevoUsuario) => {
    setLoading(true);
    try {
      const response = await crearClub(nuevoUsuario);
      console.log(response);
      Swal.fire({
        icon: "success",
        title: "Éxito",
        text: `${response.message}`,
      });
    } catch (err) {
      // setError('Error al crear usuario.', err);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err || "Autenticación fallida",
      });
    } finally {
      setLoading(false);
      setRefrescar(!refrescar);
    }
  };

  if (loading) {
    return (
      <CargandoDatos cargando={loading} />
      // <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
      //     <CircularProgress />
      // </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: 2, textAlign: "center", color: "error.main" }}>
        <Typography variant="body1">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box>
      {/* Título y Switch condicional */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" gutterBottom>
          {modoEdicion
            ? `Editando el usuario: ${usuarioedicion?.user?.name || ""}`
            : `Nuevo Usuario para Rol: ${rol}`}
        </Typography>

        {modoEdicion && (
          <FormControlLabel
            control={
              <Switch
                checked={modoEdicion} // Directamente enlazado al estado
                onChange={handleModoEdicionChange} // Maneja los cambios
                color="primary"
              />
            }
            label="Modo Edición"
          />
        )}
      </Box>

      {/* Campo de Nombre */}
      <TextField
        fullWidth
        margin="normal"
        label="Nombre"
        value={nombre}
        onChange={(e) => setNombre(e.target.value)}
        error={!!formError.nombre}
        helperText={formError.nombre}
      />

      {/* Campo de Email */}
      <TextField
        fullWidth
        margin="normal"
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={!!formError.email}
        helperText={formError.email}
      />

      {/* Campos de Contraseña */}
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        <TextField
          fullWidth
          margin="normal"
          label="Contraseña"
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!formError.password}
          helperText={formError.password}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          margin="normal"
          label="Confirmar Contraseña"
          type={showPassword ? "text" : "password"}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          error={!!formError.confirmPassword}
          helperText={formError.confirmPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      <FormControl fullWidth margin="normal" error={!!formError.deporte}>
        <InputLabel id="deporte-select-label">Deportes</InputLabel>
        <Select
          labelId="deporte-select-label"
          value={selectedDeporte}
          onChange={handleDeporteChange}
        >
          <MenuItem value="" disabled>
            Selecciona un deporte
          </MenuItem>
          {deportes.map((deporte) => (
            <MenuItem key={deporte.id} value={deporte.id}>
              {deporte.nombre}
            </MenuItem>
          ))}
        </Select>
        {formError.deporte && (
          <Typography variant="caption" color="error">
            {formError.deporte}
          </Typography>
        )}
      </FormControl>

      <FormControl fullWidth margin="normal" error={!!formError.modalidad}>
        <InputLabel id="modalidad-select-label">Modalidad</InputLabel>
        <Select
          labelId="modalidad-select-label"
          value={selectedModalidad}
          onChange={handleModalidadChange}
        >
          <MenuItem value="" disabled>
            Selecciona una modalidad
          </MenuItem>
          {modalidades.map((modalidad) => (
            <MenuItem key={modalidad.id} value={modalidad.id}>
              {modalidad.nombre}
            </MenuItem>
          ))}
        </Select>
        {formError.modalidad && (
          <Typography variant="caption" color="error">
            {formError.modalidad}
          </Typography>
        )}
      </FormControl>

      {/* Botón de Enviar */}
      {/* Cambia dinámicamente el botón */}
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ marginTop: 2 }}
        onClick={usuarioedicion ? handleUpdate : handleSubmit}
      >
        {usuarioedicion ? "Actualizar Usuario" : "Guardar Usuario"}
      </Button>
    </Box>
  );
};

export default NuevoUsuario;
