import React, { useState, useEffect,useCallback } from "react";
import Cropper from "react-easy-crop";
import {
  Box,
  Button,
  CircularProgress,
  Slider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { getCroppedImg } from "./../Auxiliares/GetCroppedImg"; // Función auxiliar para recortar la imagen
import { uploadImageTerrenoJuego } from "../servicios/llamadasApi"; // Función para subir la imagen
// import setImagenPerfil  from '../servicios/UserService'
import userService from "./../servicios/UserService";
import { useNavbar } from "../servicios/NavbarProvider";
import CargandoDatos from "../Auxiliares/CargandoDatos";

const ImageUploader = ({ onUpload, onSubmit }) => {
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null); // Para almacenar la URL de la imagen recortada
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(2);
  const [cropArea, setCropArea] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const { setNavbarState } = useNavbar();
  useEffect(() => {
    // Obtiene la URL de la imagen actual del usuario
    const fetchImage = async () => {
      try {
        const imageUrl = await userService.getImagenTerrenoJuego();
        setCurrentImage(imageUrl);
      } catch (error) {
        console.error("Error al obtener la imagen actual:", error);
      }
    };

    fetchImage();
  }, [uploading]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader.result);
      reader.readAsDataURL(file);
      setOpen(true); // Abrimos el modal para recortar
    }
  };

  // Handle when cropping area is complete
  const handleCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    if (croppedAreaPixels.width > 0 && croppedAreaPixels.height > 0) {
      setCropArea(croppedAreaPixels); // Guardamos el área de recorte solo si es válida
    }
  }, []);

  const handleCropConfirm = async () => {
    // Verificamos que el área de recorte es válida antes de recortar
    if (!cropArea || cropArea.width <= 0 || cropArea.height <= 0) {
      console.error("El área de recorte no es válida");
      alert(
        "Por favor, asegúrate de seleccionar un área válida para recortar."
      );
      return;
    }

    try {
      setLoading(true);
      const croppedImgBlob = await getCroppedImg(image, cropArea); // Recortamos la imagen
      const croppedImgUrl = URL.createObjectURL(croppedImgBlob); // Creamos un ObjectURL a partir del Blob
      setCroppedImage(croppedImgBlob); // Guardamos el Blob de la imagen recortada
      setCroppedImageUrl(croppedImgUrl); // Guardamos la URL del Blob para la vista previa
      setOpen(false); // Cerramos el modal de recorte
      setLoading(false);
    } catch (error) {
      console.error("Error recortando la imagen:", error);
      setLoading(false);
    }
  };

  const handleUpload = async () => {
    // Verificamos que hay una imagen recortada antes de intentar subirla
    if (!croppedImage) {
      console.error("No hay imagen recortada para subir");
      alert("Por favor, recorta la imagen antes de intentar subirla.");
      return;
    }

    try {
      setUploading(true);
      const response = await uploadImageTerrenoJuego(croppedImage); // Subimos la imagen recortada
      console.log("Imagen subida con éxito:", response.image_url);
      userService.setImagenTerrenoJuego(response.image_url);
      setUploading(false);
      // setNavbarState({ message: "¡Navbar actualizado!" });
    } catch (error) {
      console.error("Error al subir la imagen:", error);
      alert("Hubo un error al subir la imagen.");
      setUploading(false);
    }
  };

  return (
    <Box
    sx={{
        display:'flex',
        alignItems: 'center',
        //   justifyContent: 'center',
          flexDirection:'column',
          minHeight:'100vh'
    }}
    >
      <Typography variant="h6" gutterBottom>
        Bolera del Equipo Principal
      </Typography>
      {currentImage && (
        <Box
          sx={{
            display:'flex',
            alignItems: 'center',
              justifyContent: 'center',
            width: '99%',
            // height: 300,
            borderRadius: "8px",
            overflow: "hidden",
            marginBottom: 2,
          }}
        >
          <img
            src={currentImage}
            alt="Imagen actual"
            style={{ width: "100%", height: "auto", objectPosition: "center",  }}
          />
        </Box>
      )}
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        id="upload-image"
        onChange={handleFileChange}
      />
      <label htmlFor="upload-image">
        <Button variant="contained" component="span">
          Seleccionar Imagen
        </Button>
      </label>

      {/* Si la imagen está recortada, la mostramos */}
      {croppedImageUrl && (
        <Box
          sx={{
            cursor: "pointer",
            maxWidth: '99%',
            // maxHeight: 300,
            // overflow: 'hidden',
            margin: "0 auto", // Aseguramos que el Box tenga espacio
            marginTop: 4, // Un pequeño espacio entre la imagen y el botón
          }}
        >
          <Box
            onClick={() => setOpen(true)} // Al hacer clic volvemos a abrir el cropper
          >
            <img
              src={croppedImageUrl} // Usamos la URL del Blob aquí
              alt="Vista previa"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
          <Box mt={2}>
            {/* Botón de subir imagen */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              disabled={uploading} // Deshabilitamos el botón mientras se sube la imagen
              sx={{
                display: "inline-block",
                width: "100%", // Aseguramos que el botón ocupe todo el espacio disponible
              }}
            >
              {uploading ?   <CargandoDatos cargando={loading}/> : "Subir Imagen"}
            </Button>
          </Box>
        </Box>
      )}

      {/* Modal para recortar la imagen */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Recorta tu imagen</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: 400,
              background: "#333",
            }}
          >
            {image && (
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={1.5} // Relación de aspecto cuadrada
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={handleCropComplete}
              />
            )}
          </Box>
          <Box mt={2}>
            <Typography>Zoom</Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancelar</Button>
          <Button
            onClick={handleCropConfirm}
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Recortar"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ImageUploader;
