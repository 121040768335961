import React, { useState, useEffect } from "react";
import { getRoles } from "./servicios/llamadasApi"; // Asegúrate de que la ruta sea correcta
import { Box, Tab, Tabs, CircularProgress, Typography } from "@mui/material";
import MostrarUsuarios from "./componentes/Usuarios/MostrarUsuarios";
import NuevoUsuario from "./componentes/Usuarios/NuevoUsuario";
import CargandoDatos from "./Auxiliares/CargandoDatos";
const Usuarios = () => {
  // Estado para almacenar los roles y manejar la pestaña activa
  const [roles, setRoles] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState(null);
  const [refrescar, setRefrescar] = useState(false);
  const handleUsuarioClick = (usuario) => {
    setUsuarioSeleccionado(usuario); // Actualiza el estado con el usuario seleccionado
  };

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await getRoles(); // Llamamos al servicio para obtener roles
        const rolesArray = Object.values(response);
        setRoles(rolesArray); // Guardamos solo los valores de los roles
        setLoading(false);
      } catch (error) {
        setError("Hubo un error al cargar los roles.");
        setLoading(false);
      }
    };

    fetchRoles();
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); // Cambia la pestaña activa
  };
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CargandoDatos cargando={loading} />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ width: "100%" }}>
      {/* Tabs */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="roles tabs"
        sx={{
          width: "100%",
          overflowX: "auto",
        }}
      >
        {roles.map((role, index) => (
          <Tab
            label={role}
            key={index}
            sx={{
              color: activeTab === index ? "primary.main" : "text.primary",
            }}
          />
        ))}
      </Tabs>

      {/* Content */}
      <Box sx={{ padding: 2 }}>
        <Box
          sx={{
            display: "flex", // Utilizamos Flexbox
            gap: 2, // Espaciado entre los componentes
            flexWrap: "wrap", // Permite que se ajusten en pantallas pequeñas
          }}
        >
          {/* Componente MostrarUsuarios ocupa la mitad */}
          <Box sx={{ flex: 1, minWidth: "300px" }}>
            <MostrarUsuarios
              rol={roles[activeTab]}
              onUsuarioClick={handleUsuarioClick}
              refrescar={refrescar}
            />
          </Box>

          {/* Componente NuevoUsuario ocupa la otra mitad */}
          <Box sx={{ flex: 1, minWidth: "300px" }}>
            <NuevoUsuario
              rol={roles[activeTab]}
              usuarioedicion={usuarioSeleccionado}
              setRefrescar={setRefrescar}
              refrescar={refrescar}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Usuarios;
