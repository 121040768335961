import React, { useEffect, useState, useMemo } from "react";
import { getUsuariosporRol } from "../../servicios/llamadasApi";
import {
  CircularProgress,
  Card,
  CardContent,
  Typography,
  Box,
  Alert,
  Avatar,
  Grid,
  CardMedia,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CargandoDatos from "../../Auxiliares/CargandoDatos";
import TarjetaUsuario from "./TarjetaUsuarios";
import ClearIcon from "@mui/icons-material/Clear";
const MostrarUsuarios = ({
  rol,
  anchocompleto = false,
  onUsuarioClick,
  refrescar,
}) => {
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filtro, setFiltro] = useState(""); // Estado para el filtro

    // Filtra los usuarios con useMemo para optimización
    const usuariosFiltrados = useMemo(() => {
      return usuarios.filter((usuario) =>
        usuario.user.name.toLowerCase().includes(filtro.toLowerCase())
      );
    }, [usuarios, filtro]);
    const limpiarFiltro = () => setFiltro(""); // Limpia el filtro
  const handleUsuarioClick = (usuario) => {
    // Comprueba si onUsuarioClick es una función antes de llamarla
    if (typeof onUsuarioClick === "function") {
      onUsuarioClick(usuario);
    }
  };

  useEffect(() => {
    const fetchUsuarios = async () => {
      setLoading(true); // Inicia el loading al llamar la API

      try {
        const response = await getUsuariosporRol(rol);
        setUsuarios(response); // Guarda los usuarios en el estado
        setLoading(false); // Termina el loading
        setError(null); // Limpiar el error en caso de éxito
      } catch (error) {
        setError("Hubo un error al obtener los usuarios.");
        setLoading(false); // Termina el loading
      }
    };

    fetchUsuarios();
  }, [rol, refrescar]); // Se ejecuta cada vez que el rol cambie

  // Si está cargando
  if (loading) {
    return (
      <CargandoDatos cargando={loading} />
      // <Box
      //   sx={{
      //     display: "flex",
      //     justifyContent: "center",
      //     alignItems: "center",
      //     height: "100vh",
      //   }}
      // >
      //   <CircularProgress />
      // </Box>
    );
  }

  // Si hay error
  if (error) {
    return (
      <Box sx={{ width: "100%", p: 2 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  // Determina la cantidad de tarjetas por fila dependiendo de anchocompleto
  const tarjetasPorFila = anchocompleto ? 3 : 6; // 4 tarjetas por fila si anchocompleto es true

  return (
    <Box sx={{ p: 2, width: "99%" }}>
      <Typography
        variant="h4"
        gutterBottom
        align="center"
        sx={{ fontWeight: "bold" }}
      >
        Usuarios con rol: {rol}
      </Typography>
      {/* Campo para filtrar con botón de limpiar */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 2 }}>
        <TextField
          fullWidth
          label="Filtrar por nombre"
          variant="outlined"
          value={filtro}
          onChange={(e) => setFiltro(e.target.value)}
          InputProps={{
            endAdornment: filtro && (
              <InputAdornment position="end">
                <IconButton onClick={limpiarFiltro} edge="end">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={limpiarFiltro}
          disabled={!filtro} // Deshabilita el botón si no hay filtro
        >
          Limpiar
        </Button>
      </Box>

      {/* Si no hay usuarios */}
      {usuariosFiltrados.length === 0 ? (
        <Alert severity="info" sx={{ mb: 2 }}>
          No se encontraron usuarios con el rol "{rol}".
        </Alert>
      ) : (
        <Box
          sx={{
            maxHeight: "70vh",
            overflowY: "auto",
            width: "100%", // Esto asegura que el contenedor ocupe todo el ancho disponible
            maxWidth: "100%", // Esto también asegura que no haya un límite de ancho
          }}
        >
          <Grid container sx={{ width: "100%" }} spacing={2}>
            {usuariosFiltrados.map((usuario) => (
              <>
                <TarjetaUsuario
                  key={usuario.id}
                  usuario={usuario}
                  onUsuarioClick={handleUsuarioClick}
                  tarjetasPorFila={tarjetasPorFila}
                />


              </>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default MostrarUsuarios;
