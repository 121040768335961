import React, { useEffect, useState } from "react";
import { Grid, Box, Paper } from "@mui/material";
import ResumenClub from "../componentes/Contabilidad/ResumenClub";
import MovimientosporAno from "../componentes/Contabilidad/MovimientosporAno";
import BalanceporEquipo from "../componentes/Contabilidad/BalanceporEquipo";
import MovimientosFormulario from "../componentes/Contabilidad/MovimientosFormulario";

import userService from "../servicios/UserService";
import CargandoDatos from "../Auxiliares/CargandoDatos";

const Contabilidad = () => {
  const [clubData, setClubData] = useState(null); // Estado principal
  const [loading, setLoading] = useState(true); // Estado de carga
  const [error, setError] = useState(null); // Estado de error
  const [refrescar, setRefrescar] = useState(false); // Estado de error

  // Función para cargar los datos iniciales del club
  const fetchClubData = async () => {
    setLoading(true); // Mostrar estado de carga
    setError(null); // Reiniciar estado de error
    try {
      const club = await userService.getAuthData();
      console.log("🚀 ~ fetchClubData ~ club:", club);

      setClubData(club);
    } catch (err) {
      console.error("Error cargando datos del club:", err);
      setError("Hubo un problema al cargar los datos del club.");
    } finally {
      setLoading(false); // Finalizar estado de carga
    }
  };

  // Llamar a la función para cargar datos al montar el componente
  useEffect(() => {
    fetchClubData();
  }, []);

  // Función para actualizar los movimientos y recargar datos
  const handleMovementAdded = () => {
    setRefrescar(!refrescar);
  };

  // Mostrar estado de carga o error
  if (loading) return <CargandoDatos cargando={loading} />;
  if (error) return <div>Error: {error}</div>;

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={3}>
        {/* Resumen del Club */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <ResumenClub
              clubId={clubData.usuario.club.id}
              refrescar={refrescar}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            {/* <BalanceporEquipo clubId={clubData.usuario.club.id} refrescar={refrescar} /> */}
          </Paper>
        </Grid>

        {/* Formulario y Balance por Equipo */}
        <Grid item xl={4} xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2 }}>
                <MovimientosFormulario
                  clubId={clubData.usuario.club.id}
                  onMovementAdded={handleMovementAdded}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        {/* Movimientos por Año */}
        <Grid item xl={8} xs={12}>
          <Paper sx={{ p: 2 }}>
            <MovimientosporAno
              clubId={clubData.usuario.club.id}
              refrescar={refrescar}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contabilidad;
