// src/services/apiService.js
import axios from 'axios';
import userService from './UserService';
// Configura la instancia de Axios

const apiUrl =
  process.env.REACT_APP_API_URL && process.env.REACT_APP_API_URL.trim()
    ? process.env.REACT_APP_API_URL
    : `${window.location.origin}/api`;

// console.log('API URL:', apiUrl); // Verifica qué URL se está usando.
const api = axios.create({
  baseURL: apiUrl, // Base URL desde las variables de entorno
  headers: {
    'Content-Type': 'application/json', // Tipo de contenido por defecto
  },
});

// Interceptor para incluir el token automáticamente en cada solicitud
api.interceptors.request.use(
  async (config) => {
    try {
      // Obtén el token de manera asíncrona usando el userService
      const token = await userService.getToken();

      // Si el token existe, se incluye en los headers de la solicitud
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config; // Devuelve la configuración de la solicitud
    } catch (error) {
      return Promise.reject(error); // Si ocurre un error, lo manejamos aquí
    }
  },
  (error) => Promise.reject(error) // Manejar errores de configuración de la solicitud
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        // Redirigir al usuario al login si el token expira
        window.location.href = '/login';
      }
      return Promise.reject(error);
    }
  );
export default api;
