// userService.js
class UserService {
    // Obtener los datos del usuario desde localStorage
    getAuthData() {
      return new Promise((resolve, reject) => {
        try {
          const authData = localStorage.getItem('authData');
          if (authData) {
            resolve(JSON.parse(authData)); // Resuelve la promesa con los datos
          } else {
            reject('No authData found'); // Rechaza la promesa si no hay datos
          }
        } catch (error) {
          reject(error); // En caso de error, rechaza la promesa
        }
      });
    }
  
    // Método para obtener el status a ver si cambia el build
    async getStatus() {
      try {
        const authData = await this.getAuthData();
        return authData.status;
      } catch (error) {
        return null; // Si ocurre un error (no hay datos), retorna null
      }
    }
    async getImagen() {
      try {
        const authData = await this.getAuthData();
        return authData.imagen;
      } catch (error) {
        return null; // Si ocurre un error (no hay datos), retorna null
      }
    }
    async getImagenTerrenoJuego() {
      try {
        const authData = await this.getAuthData();
        // console.log('%csrc\servicios\UserService.js:39 object', 'color: #007acc;', authData);
        if( authData.usuario.club.equipos &&
          authData.usuario.club.equipos.length > 0 &&
          authData.usuario.club.equipos[0].terreno_juego)
        {
          return authData.usuario.club.equipos[0].terreno_juego.imagenterrenojuego;
        }
        
      } catch (error) {
        return null; // Si ocurre un error (no hay datos), retorna null
      }
    }
    async setImagenPerfil(imageUrl) {
      try {
        // Obtén los datos del usuario desde el localStorage
        let userData = await this.getAuthData() 
    
        if (!userData) {
          throw new Error('No se encontraron los datos del usuario en el localStorage');
        }
    
        // Actualiza la URL de la imagen en los datos del usuario
        userData.imagen = imageUrl;
        console.log('%csrc\servicios\UserService.js:48 imageUrl', 'color: #007acc;', imageUrl);
        console.log(userData)
    
        // Guarda los datos del usuario actualizados en el localStorage
        localStorage.setItem('authData', JSON.stringify(userData));
    
        return userData.imagen; // Devuelve la nueva URL de la imagen
    
      } catch (error) {
        console.error("Error al actualizar la imagen en el localStorage:", error);
        return null; // Si ocurre un error, retorna null
      }
    }
    async setImagenTerrenoJuego(imageUrl) {
      try {
        // Obtén los datos del usuario desde el localStorage
        let userData = await this.getAuthData() 
    
        if (!userData) {
          throw new Error('No se encontraron los datos del usuario en el localStorage');
        }
        // console.log(userData)
        userData.usuario.club.equipos[0].terreno_juego.imagenterrenojuego=imageUrl
        // Actualiza la URL de la imagen en los datos del usuario
        console.log('%csrc\servicios\UserService.js:86 userData', 'color: #007acc;', userData);
        console.log('%csrc\servicios\UserService.js:48 imageUrl', 'color: #007acc;', imageUrl);
        
    
        // Guarda los datos del usuario actualizados en el localStorage
        localStorage.setItem('authData', JSON.stringify(userData));
    
        return userData.usuario.club.equipos[0].terreno_juego.imagenterrenojuego; // Devuelve la nueva URL de la imagen
    
      } catch (error) {
        console.error("Error al actualizar la imagen en el localStorage:", error);
        return null; // Si ocurre un error, retorna null
      }
    }
    // Método para obtener el mensaje
    async getMessage() {
      try {
        const authData = await this.getAuthData();
        return authData.message;
      } catch (error) {
        return null; // Si ocurre un error, retorna null
      }
    }
  
    // Método para obtener el token
    async getToken() {
      try {
        const authData = await this.getAuthData();
        return authData.token;
      } catch (error) {
        return null; // Si ocurre un error, retorna null
      }
    }
  
    // Método para obtener el rol
    async getRol() {
      try {
        const authData = await this.getAuthData();
        return authData.rol;
      } catch (error) {
        return null; // Si ocurre un error, retorna null
      }
    }
  
    // Método para obtener el nombre
    async getNombre() {
      try {
        const authData = await this.getAuthData();
        return authData.nombre;
      } catch (error) {
        return null; // Si ocurre un error, retorna null
      }
    }
  
    // Método para comprobar si el usuario está logueado
    async isUserLoggedIn() {
      try {
        const authData = await this.getAuthData();
        return authData.status;
      } catch (error) {
        return false; // Si ocurre un error, retorna false
      }
    }
  
    // Método para guardar datos de autenticación
    saveAuthData(data) {
      return new Promise((resolve, reject) => {
        try {
          localStorage.setItem('authData', JSON.stringify(data));
          resolve(true); // Resuelve la promesa si se guarda correctamente
        } catch (error) {
          reject('Error saving authData'); // Rechaza si ocurre un error
        }
      });
    }
  
    // Método para limpiar los datos de autenticación
    clearAuthData() {
      return new Promise((resolve, reject) => {
        try {
          localStorage.removeItem('authData');
          resolve(true); // Resuelve la promesa si se elimina correctamente
        } catch (error) {
          reject('Error clearing authData'); // Rechaza si ocurre un error
        }
      });
    }
  }
  
  const userService = new UserService(); // Instancia del servicio
  
  export default userService; // Exportamos la instancia para usarla en otras partes de la aplicación
  