import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import CardVistosa from "./componentes/CardVistosa";
import ResumenClub from "./componentes/Contabilidad/ResumenClub";
const Dashboard = () => {
  const [token, setToken] = useState("");
  const [rol, setRol] = useState("");
  const [userdata, setUserdata] = useState();

  useEffect(() => {
    const storedAuthData = localStorage.getItem("authData");
    if (storedAuthData) {
      const parsedAuthData = JSON.parse(storedAuthData);
      setUserdata(parsedAuthData);
      const authToken = parsedAuthData.token;
      const userRol = parsedAuthData.rol;

      setToken(authToken);
      setRol(userRol);
    }
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={4} sx={{ padding: "20px" }}>
        <CardVistosa type="club" />
        <CardVistosa type="equipo" />
        <CardVistosa type="jugador" />
        <CardVistosa type="arbitro" />
      </Grid>

      {/* Mostrar Grid adicional si el rol es "Club" */}
      {rol === "Club" && (

          <Paper sx={{ p: 2 }}>
            <ResumenClub clubId={userdata.usuario.club.id} />
          </Paper>
  
      )}
    </Box>
  );
};

export default Dashboard;
