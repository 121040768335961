import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import api from "../../servicios/apiService";
import CargandoDatos from "../../Auxiliares/CargandoDatos";

const MovimientosporAno = ({ clubId, refrescar }) => {
  const [movements, setMovements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMovements = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get(`club/movimientosanuales`);
        setMovements(response.data);
      } catch (err) {
        console.error("Error al cargar movimientos por año:", err);
        setError("Hubo un problema al cargar los movimientos.");
      } finally {
        setLoading(false);
      }
    };

    fetchMovements();
  }, [clubId, refrescar]);

  if (loading) return <CargandoDatos cargando={loading} />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ width: "100%", padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Movimientos por Año
      </Typography>
      {movements.map((yearData) => {
        const totalIngresos = yearData.totales.ingresos;
        const totalGastos = yearData.totales.gastos;
        const diferencia = totalIngresos - totalGastos;

        return (
          <Accordion key={yearData.año}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Typography variant="h6">Año: {yearData.año}</Typography>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", justifyContent: "space-between", marginTop: 1 }}
                  color="text.primary"
                >
                  <span>Total ingresos: {totalIngresos}</span>
                  <span>Total gastos: {totalGastos}</span>
                  <span>Diferencia: {diferencia}</span>
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="subtitle1" gutterBottom>
                Detalles por equipo
              </Typography>
              <List>
                {yearData.equipos.map((equipo) => (
                  <React.Fragment key={equipo.equipo_id}>
                    <ListItem>
                      <ListItemText
                        primary={equipo.equipo.nombre}
                        secondary={`Ingresos: ${equipo.totales.ingresos} | Gastos: ${equipo.totales.gastos}`}
                      />
                    </ListItem>
                    <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Fecha</TableCell>
                            <TableCell>Importe</TableCell>
                            <TableCell>Concepto</TableCell>
                            <TableCell>Tipo</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {equipo.detalles.map((detalle, index) => (
                            <TableRow key={index}>
                              <TableCell>{detalle.fecha}</TableCell>
                              <TableCell>{detalle.importe}</TableCell>
                              <TableCell>{detalle.concepto}</TableCell>
                              <TableCell>{detalle.tipo}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default MovimientosporAno;
