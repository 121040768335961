import React, { createContext, useEffect, useState } from 'react';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const storedAuthData = localStorage.getItem('authData');
    if (storedAuthData) {
        try {
          const parsedData = JSON.parse(storedAuthData);
          // console.log("🚀 ~ useEffect ~ parsedData:", parsedData)
          
          if (parsedData && parsedData.token) { // Validar estructura esperada
            setAuthData(parsedData);
          } else {
            console.warn('authData no contiene un token válido');
          }
        } catch (error) {
          console.error('Error al analizar authData:', error);
        }
      }
    setIsLoading(false); // Marcar como cargado
  }, []);

  return (
    <AuthContext.Provider value={{ authData, setAuthData, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
