import React from "react";
import { Container, Box, Typography } from "@mui/material";
import api from "./servicios/apiService";
import MyNavbar from "./MyNavbar";
import { DynamicDataTable } from "dynamic-data-table";

const Ejecuciones = () => {
  const fetchData = async () => {
    const response = await api.get("usuarios/administradores");
    return response.data;
  };

  const updateData = async (record) => {
    await api.put(`/data/${record.id}`, record);
  };

  return (
    <Box>
      {/* <MyNavbar /> */}

      <Container
        maxWidth="md"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          textAlign: "center",
        }}
      >
        <Typography variant="h3" component="h1">
          Página de Ejecuciones
        </Typography>
        <DynamicDataTable
          fetchData={fetchData}
          updateData={updateData}
          onError={(error) => console.error("Error fetching data:", error)}
          onUpdateSuccess={(record) =>
            console.log("Record updated successfully:", record)
          }
        />
      </Container>
    </Box>
  );
};

export default Ejecuciones;
