const routesConfig = [
    { label: "Ejecuciones", path: "/ejecuciones", component: "Ejecuciones", roles: ["SuperAdmin", "Admin"] },
    { label: "Presupuestos", path: "/presupuestos", component: "Presupuestos", roles: ["SuperAdmin", "Admin"] },
    { label: "Usuarios", path: "/usuarios", component: "Usuarios", roles: ["SuperAdmin", "Admin"] },
    { label: "Configuraciones", path: "/configuracion", component: "Configuraciones", roles: ["SuperAdmin"] },
    { label: "Mi Club", path: "/mi-club", component: "MiEquipo", roles: ["Club"] },
    { label: "Partidos", path: "/partidos", component: "MiEquipo", roles: ["Club"] },
    { label: "Contabilidad", path: "/contabilidad", component: "Contabilidad", roles: ["Club"] },
    { label: "Clubs", path: "/clubs", component: "Clubs", roles: null },
    { label: "Mi Perfil", path: "/mi-perfil", component: "MiPerfil", roles: null, hideInNavbar: true  },
    { label: "Inicio", path: "/", component: "Dashboard", roles: null, hideInNavbar: true },
    { label: "Login", path: "/login", component: "Login", roles: null, hideInNavbar: true },
  ];
  
  export default routesConfig;
  
  