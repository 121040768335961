import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Link,
  Avatar,
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import logo from "./recursos/diario-de-una-cebra-logo.png";
import userService from "./servicios/UserService";
import { useNavbar } from "./servicios/NavbarProvider";
import routesConfig from "./Auxiliares/routesConfig";

const MyNavbar = () => {
  const { navbarState } = useNavbar();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [role, setRole] = useState(null);
  const [userName, setUserName] = useState("");
  const [userAvatar, setUserAvatar] = useState(null);
  const navigate = useNavigate();

  // Obtener enlaces dinámicamente según el rol
  const getNavLinksForRole = (role) => {
    return routesConfig
      .filter(
        (route) =>
          route.label &&
          !route.hideInNavbar && // Excluir rutas ocultas
          (!route.roles || route.roles.includes(role))
      )
      .map((route) => ({ label: route.label, href: route.path }));
  };

  // Manejo del cierre de sesión
  const handleLogout = async () => {
    try {
      await userService.clearAuthData();
      navigate("/login");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  // Alternar el estado del drawer
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  // Cargar datos del usuario al montar el componente
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRole = await userService.getRol();
        setRole(userRole);

        const userName = await userService.getNombre();
        setUserName(userName);

        const userAvatar = await userService.getImagen();
        setUserAvatar(userAvatar);
      } catch (error) {
        console.error("Error al obtener datos del usuario:", error);
      }
    };

    fetchUserData();
  }, [navbarState]);

  // Generar URL para evitar caché en imágenes
  const getAvatarUrl = (imageUrl) =>
    imageUrl ? `${imageUrl}?t=${new Date().getTime()}` : null;

  // Obtener los enlaces visibles para el rol actual
  const navLinks = role ? getNavLinksForRole(role) : [];

  return (
    <>
      <AppBar
        position="static"
        color="primary"
        sx={{
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)", // Sombra sutil
          transition: "box-shadow 0.3s ease", // Animación de sombra
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 20px", // Espaciado más amplio
          }}
        >
          {/* Logo */}
          <Link href="/" color="inherit" underline="none">
            <Box
              component="img"
              src={logo}
              alt="Logo"
              sx={{
                m: 1,
                width: "110px", // Ajusta el tamaño del logo
                objectFit: "contain",
              }}
            />
            
          </Link>

          {/* Enlaces para pantallas grandes */}
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
            }}
          >
            {navLinks.map((link) => (
              <Link
                key={link.label}
                href={link.href}
                color="inherit"
                underline="none"
                sx={{
                  marginRight: 2,
                  transition: "color 0.3s ease", // Transición suave al pasar el mouse
                  "&:hover": { color: "secondary.main" }, // Efecto hover
                }}
              >
                {link.label}
              </Link>
            ))}
            <Link href="/mi-perfil" style={{ textDecoration: "none" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 2,
                }}
              >
                {/* Nombre del usuario */}
                {userName && (
                  <Box
                    sx={{
                      marginRight: 2,
                      color: "white",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {userName}
                  </Box>
                )}
                {/* Avatar */}
                <Avatar
                  alt={userName || "Usuario"}
                  src={getAvatarUrl(userAvatar)}
                  sx={{
                    width: 40,
                    height: 40,
                    bgcolor: userAvatar ? "white" : "secondary.main",
                    border: "2px solid white", // Borde blanco
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Sombra suave en avatar
                    transition: "transform 0.3s ease", // Efecto de animación
                    "&:hover": { transform: "scale(1.1)" }, // Efecto hover de agrandar
                  }}
                >
                  {!userAvatar && (userName ? userName[0].toUpperCase() : "?")}
                </Avatar>
              </Box>
            </Link>
            {/* Botón de cerrar sesión */}
            <Button
              color="inherit"
              variant="outlined"
              onClick={handleLogout}
              sx={{
                borderRadius: "20px", // Bordes redondeados
                transition: "background-color 0.3s ease", // Transición suave
                "&:hover": {
                  backgroundColor: "white",
                  color: "primary.main",
                },
              }}
            >
              Cerrar sesión
            </Button>
          </Box>

          {/* Menú hamburguesa para pantallas pequeñas */}
          <IconButton
            color="inherit"
            edge="end"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Drawer para pantallas pequeñas */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.3)", // Sombra para el drawer
            width: "250px",
            transition: "width 0.3s ease", // Animación de apertura
          },
        }}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {navLinks.map((link) => (
              <ListItem button key={link.label} component="a" href={link.href}>
                <ListItemText primary={link.label} />
              </ListItem>
            ))}
            <ListItem button onClick={handleLogout}>
              <ListItemText primary="Cerrar sesión" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default MyNavbar;
