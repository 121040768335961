import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Card, CardContent, Typography, Grid, Box, useTheme } from '@mui/material';
import logo from './recursos/logo_1.png'; // Asegúrate de reemplazar esto con la ruta correcta a tu logo
import authService from './servicios/authService'; // Importa el servicio de autenticación

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();


  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const authData = await authService.login(email, password);

      // Redirige al usuario si el login fue exitoso
      Swal.fire({
        icon: 'success',
        title: 'Bienvenido',
        text: `Hola, ${authData.nombre}!`,
      });
      navigate('/');
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: error.message || 'Autenticación fallida',
      });
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '100vh',
        // backgroundColor: theme.palette.background.default,
        padding: 2,
      }}
    >
      <Card 
        sx={{
          maxWidth: 400,
          width: '100%',
          borderRadius: 2,
          boxShadow: 5,
          backgroundColor: 'rgba(255,255,255,0.9)',
        }}
      >
        <CardContent>
          <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
            <img
              src={logo}
              alt="Logo"
              style={{
                width: '99%',
                marginBottom: '20px',
              }}
            />
          </Box>

          <Typography variant="h5" align="center" gutterBottom>
            Iniciar sesión
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              label="Correo electrónico"
              type="email"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{
                input: {
                  borderRadius: '5px',
                },
              }}
            />

            <TextField
              label="Contraseña"
              type="password"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              sx={{
                input: {
                  borderRadius: '5px',
                },
              }}
            />

            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                marginTop: 2,
                borderRadius: '5px',
                padding: '12px',
                backgroundColor: theme.palette.secondary.main,
                '&:hover': {
                  backgroundColor: theme.palette.secondary.dark,
                },
              }}
            >
              Iniciar sesión
            </Button>
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Login;
