import React, { useEffect, useState } from 'react';
import { Typography, Card, CardContent, Grid, Box } from '@mui/material';
import { ErrorOutline } from '@mui/icons-material'; // Ícono de error
import CargandoDatos from '../../Auxiliares/CargandoDatos'; // Tu componente de carga
import api from '../../servicios/apiService';

const ResumenClub = ({ clubId ,refrescar}) => {
  const [summary, setSummary] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSummary = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get('club/balance');
        console.log("🚀 ~ fetchSummary ~ response:", response);
        setSummary(response.data);
      } catch (err) {
        console.error("Error al cargar el resumen del club:", err);
        setError("Hubo un problema al cargar el resumen del club.");
      } finally {
        setLoading(false);
      }
    };

    fetchSummary();
  }, [clubId,refrescar]);

  if (loading) return <CargandoDatos cargando={loading} />; // Usamos tu componente de carga

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <ErrorOutline color="error" />
        <Typography variant="h6" color="error" style={{ marginLeft: 8 }}>
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" color="primary" gutterBottom>
        Economía de tu Club
      </Typography>
      {summary.length > 0 ? (
        <Grid container spacing={3}>
          {summary.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item.year}>
              <Card sx={{ minHeight: 180, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <CardContent>
                  <Typography variant="h6" color="secondary" gutterBottom>
                    Año: {item.year}
                  </Typography>
                  <Typography variant="body1">Total Ingresos: {item.total_ingresos} €</Typography>
                  <Typography variant="body1">Total Gastos: {item.total_gastos} €</Typography>
                  <Typography variant="body1" color={item.balance >= 0 ? 'primary' : 'error'}>
                    Balance: {item.balance} €
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="body1" color="textSecondary">
          No hay datos disponibles.
        </Typography>
      )}
    </Box>
  );
};

export default ResumenClub;
