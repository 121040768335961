import api from './apiService';

// Función para obtener presupuestos
export const contarClubes = async (params) => {
  try {
    const response = await api.get('/clubs/count', { params }); // Pasar parámetros como query string
    return response.data; // Retornar los datos obtenidos
  } catch (error) {
    console.error('Error al obtener los presupuestos:', error);
    throw error; // Lanza el error para manejarlo en el componente
  }
};
// Función para obtener presupuestos
export const getClubes = async (params) => {
    try {
      const response = await api.get('/clubs', { params }); // Pasar parámetros como query string
      return response.data; // Retornar los datos obtenidos
    } catch (error) {
      console.error('Error al obtener los clubs:', error);
      throw error; // Lanza el error para manejarlo en el componente
    }
  };

  export const getRoles = async (params) => {
    try {
      const response = await api.get('/roles', { params }); // Pasar parámetros como query string
      return response.data; // Retornar los datos obtenidos
    } catch (error) {
      console.error('Error al obtener los roles:', error);
      throw error; // Lanza el error para manejarlo en el componente
    }
  };
  export const getDeportes = async (params) => {
    try {
      const response = await api.get('/deportes', { params }); // Pasar parámetros como query string
      return response.data; // Retornar los datos obtenidos
    } catch (error) {
      console.error('Error al obtener los deportes:', error);
      throw error; // Lanza el error para manejarlo en el componente
    }
  };
  export const getModalidades = async (params) => {
    try {
      const response = await api.get('/modalidades', { params }); // Pasar parámetros como query string
      return response.data; // Retornar los datos obtenidos
    } catch (error) {
      console.error('Error al obtener los modalidades:', error);
      throw error; // Lanza el error para manejarlo en el componente
    }
  };
  export const getUsuariosporRol = async (rol,params) => {
    // console.log(rol)
    const roles =new Map([
      ['Club', '/clubs'],
      ['Jugador', '/jugadores'],
      ['Arbitro', '/arbitros'],
      ['Admin', '/usuarios/administradores']
   ]);
  //  console.log(roles.get(rol))
 
    try {
      const response = await api.get(roles.get(rol), { params }); // Pasar parámetros como query string
      return response.data; // Retornar los datos obtenidos
    } catch (error) {
      console.error('Error al obtener getUsuariosporRol '+rol +' ', error);
      throw error; // Lanza el error para manejarlo en el componente
    }
  };
  export const crearClub = async (params) => {
    // console.log(rol)
    const roles =new Map([
      ['Club', '/clubs'],
      ['Jugador', '/jugadores'],
      ['Arbitro', '/arbitros'],
      ['Admin', '/usuarios/administradores']
   ]);
   console.log(params)
   const slug=roles.get(params.rol)
    try {
      const response = await api.post(slug,  params ); // Pasar parámetros como query string
      return response.data; // Retornar los datos obtenidos
    } catch (error) {
      console.error('Error al crear usuario', error);
      throw error; // Lanza el error para manejarlo en el componente
    }
  };
  export const actualizarClub = async (params) => {
    console.log(params)
    const roles =new Map([
      ['Club', '/clubs'],
      ['Jugador', '/jugadores'],
      ['Arbitro', '/arbitros'],
      ['Admin', '/usuarios/administradores']
   ]);
   console.log(roles.get(params.rol))
 const slug=roles.get(params.rol)
    try {
      const response = await api.put(`${slug}/${params.id}`,  params ); // Pasar parámetros como query string
      return response.data; // Retornar los datos obtenidos
    } catch (error) {
      console.error('Error al crear usuario', error);
      throw error; // Lanza el error para manejarlo en el componente
    }
  };
  export const uploadImage = async (imageFile) => {
    try {
      const formData = new FormData();
      formData.append('image', imageFile); // 'image' debe coincidir con el nombre esperado en tu backend
  
      const response = await api.post('/upload-image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Necesario para subir archivos
        },
      });
  
      return response.data; // Retorna la respuesta del servidor
    } catch (error) {
      console.error('Error al subir la imagen:', error);
      throw error; // Lanza el error para manejarlo en el componente
    }
  };

  export const uploadImageTerrenoJuego = async (imageFile) => {
    try {
      const formData = new FormData();
      formData.append('image', imageFile); // 'image' debe coincidir con el nombre esperado en tu backend
  
      const response = await api.post('/upload-image-terreno-juego', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Necesario para subir archivos
        },
      });
  
      return response.data; // Retorna la respuesta del servidor
    } catch (error) {
      console.error('Error al subir la imagen:', error);
      throw error; // Lanza el error para manejarlo en el componente
    }
  };