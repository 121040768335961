import React from "react";
import MyNavbar from "./MyNavbar";
import { Outlet } from "react-router-dom";
import { Container, Box } from "@mui/material";
import { NavbarProvider } from "./servicios/NavbarProvider";

const Layout = () => {
  return (
    <NavbarProvider>
    <Box
      sx={{
        minHeight: "100vh", // Ocupa toda la altura de la pantalla
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Centra el contenido horizontalmente
      }}
    >
      {/* MyNavbar solo se renderiza en rutas protegidas */}
      <MyNavbar />

      {/* Envuelve las rutas en un Container */}
      <Container
       maxWidth={false} // Permite que el contenedor use todo el ancho del viewport
        sx={{
          width: "99%", // Ajusta el ancho al 90% del viewport
          maxWidth: "1800px", // Establece un límite personalizado (más grande que 1440px)
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
          paddingTop: 2,
          paddingBottom: 4,
        }}
      >
        <Outlet />
      </Container>
    </Box>
    </NavbarProvider>
  );
};

export default Layout;
