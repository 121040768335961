export const getCroppedImg = (imageSrc, cropAreaPixels) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
  
      image.src = imageSrc;
  
      // Verificar si la fuente de la imagen es válida antes de intentar cargarla
      image.onload = () => {
        if (!cropAreaPixels) {
          reject(new Error("cropAreaPixels no es válido"));
          return;
        }
  
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
  
        // Verificar si la imagen tiene un tamaño válido
        if (image.width <= 0 || image.height <= 0) {
          reject(new Error("La imagen no tiene dimensiones válidas"));
          return;
        }
  
        // Configurar el tamaño del canvas según el área de recorte
        canvas.width = cropAreaPixels.width;
        canvas.height = cropAreaPixels.height;
  
        // Realizar el recorte
        ctx.drawImage(
          image,
          cropAreaPixels.x,
          cropAreaPixels.y,
          cropAreaPixels.width,
          cropAreaPixels.height,
          0,
          0,
          cropAreaPixels.width,
          cropAreaPixels.height
        );
  
        // Convertir el canvas a un Blob
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Canvas está vacío'));
            return;
          }
          const file = new File([blob], 'cropped-image.png', { type: 'image/png' });
          resolve(blob); // Devolver el Blob directamente
        }, 'image/png');
      };
  
      image.onerror = (error) => {
        reject(new Error("Error al cargar la imagen: " + error.message));
      };
    });
  };
  