import React, { createContext, useState, useContext } from "react";

const NavbarContext = createContext();

export const NavbarProvider = ({ children }) => {
  const [navbarState, setNavbarState] = useState({}); // Estado para manejar la información

  return (
    <NavbarContext.Provider value={{ navbarState, setNavbarState }}>
      {children}
    </NavbarContext.Provider>
  );
};

export const useNavbar = () => useContext(NavbarContext);
