import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageUploader from "../componentes/ImageUploaderEquipo";
import api from "../servicios/apiService";
import CargandoDatos from "../Auxiliares/CargandoDatos";

// Componente para el título
const TitleSection = () => (
  <Typography variant="h3" component="h1" align="center" sx={{ my: 4 }}>
    Mi Club
  </Typography>
);

// Componente para una columna
const ColumnContent = ({ children, backgroundColor = "#ffffff" }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      p: 3,
      border: "1px solid #ccc",
      borderRadius: 2,
      boxShadow: 2,
      backgroundColor: backgroundColor,
      minHeight: "200px",
    }}
  >
    {children}
  </Box>
);

// Componente del formulario para un club
const ClubForm = ({ clubData }) => {
  const [formData, setFormData] = useState({
    nombre: clubData?.nombre || "",
    direccion: clubData?.direccion || "",
    cif: clubData?.cif || "",
    numero_de_registro: clubData?.numero_de_registro || "",
    presidente: clubData?.presidente || "",
    secretario: clubData?.secretario || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form data submitted:", formData);
    // Aquí puedes añadir la lógica para enviar los datos al servidor
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Nombre"
        name="nombre"
        value={formData.nombre}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Dirección"
        name="direccion"
        value={formData.direccion}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="CIF"
        name="cif"
        value={formData.cif}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Número de Registro"
        name="numero_de_registro"
        value={formData.numero_de_registro}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Presidente"
        name="presidente"
        value={formData.presidente}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Secretario"
        name="secretario"
        value={formData.secretario}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        Guardar
      </Button>
    </form>
  );
};

// Componente del formulario para un equipo
const TeamForm = ({ teamData }) => {
  const [formData, setFormData] = useState({
    nombre: teamData?.nombre || "",
    terreno_juego: teamData?.terreno_juego?.nombre || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Team data submitted:", formData);
    // Aquí puedes añadir la lógica para enviar los datos al servidor
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Nombre del Equipo"
        name="nombre"
        value={formData.nombre}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Terreno de Juego"
        name="terreno_juego"
        value={formData.terreno_juego}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
        Guardar
      </Button>
    </form>
  );
};

// Componente para editar múltiples equipos
const TeamsAccordion = ({ teams }) => {
  return teams.map((team) => (
    <Accordion key={team.id}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{team.nombre}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TeamForm teamData={team} />
      </AccordionDetails>
    </Accordion>
  ));
};

// Componente principal
const MiClub = () => {
  const [datos, setDatos] = useState();
  const [cargando, setCargando] = useState(false);

  useEffect(() => {
    const getDatos = async () => {
      setCargando(true);
      try {
        const response = await api.get("club/miclub");
        console.log("🚀 ~ getDatos ~ response:", response);
        setDatos(response.data);
      } catch (err) {
        console.log("%csrcpaginasmiEquipo.js:23 err", "color: #007acc;", err);
      } finally {
        setCargando(false);
      }
    };
    getDatos();
  }, []);

  if (cargando) {
    return <CargandoDatos cargando={cargando} />;
  }

  return (
    <Box sx={{ width: "100%" }}>
      {/* Título */}
      <TitleSection />

      {/* Contenedor de 3 columnas */}
      <Grid container spacing={3} sx={{ px: 3 }}>
        {/* Primera columna */}
        <Grid item xs={12} md={4}>
          <ColumnContent backgroundColor="#fff">
            <ImageUploader />
          </ColumnContent>
        </Grid>

        {/* Segunda columna */}
        <Grid item xs={12} md={4}>
          <ColumnContent>
            {datos && <ClubForm clubData={datos} />}
          </ColumnContent>
        </Grid>

        {/* Tercera columna */}
        <Grid item xs={12} md={4}>
          <ColumnContent>
            {datos?.equipos && (
              <TeamsAccordion teams={datos.equipos} />
            )}
          </ColumnContent>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MiClub;
