import axios from 'axios';
import userService from './UserService';
import api from './apiService';
const apiUrl =
  process.env.REACT_APP_API_URL && process.env.REACT_APP_API_URL.trim()
    ? process.env.REACT_APP_API_URL
    : `${window.location.origin}/api`;

// console.log('API URL:', apiUrl); // Verifica qué URL se está usando.

const authService = {
  login: async (email, password) => {
    try {
      const response = await axios.post(`${apiUrl}/auth/login`, {
        email,
        password,
      });
      // Guardar la respuesta completa en localStorage
      localStorage.setItem('authData', JSON.stringify(response.data));
      if (await userService.saveAuthData(response.data))
      {
        return response.data;
      }
      
    } catch (error) {
      console.error('Error en la autenticación:', error);
      throw error.response?.data || { message: 'Error en la solicitud' };
    }
  },
  autologin: async (userId) => {
    try {
      const response = await api.post(`usuarios/autologin/${userId}`)
      // Guardar la respuesta completa en localStorage
      localStorage.setItem('authData', JSON.stringify(response.data));
      if (await userService.saveAuthData(response.data))
      {
        return response.data;
      }
      
    } catch (error) {
      console.error('Error en autologin:', error);
      throw error.response?.data || { message: 'Error en la solicitud de autologin' };
    }
  },

};

export default authService
