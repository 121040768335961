import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ allowedRoles }) => {
  const storedAuthData = localStorage.getItem('authData');
  const parsedAuthData = JSON.parse(storedAuthData);

  // Si no hay datos de autenticación (token o rol), redirige al login
  if (!parsedAuthData || !parsedAuthData.token) {
    return <Navigate to="/login" />;
  }

  const { token, rol } = parsedAuthData;

  // Si el rol no es uno de los permitidos, redirige a una página no autorizada o a home
  if (!allowedRoles.includes(rol)) {
    return <Navigate to="/no-autorizado" />; // Redirige a una página de no autorizado o a la página de inicio
  }

  // Si el token es válido y el rol está permitido, muestra las rutas protegidas
  return <Outlet />
};

export default ProtectedRoute;

