import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute"; // Componente ProtectedRoute
import Layout from "./Layout"; // Componente que incluye MyNavbar
import Dashboard from "./Dashboard";
import Login from "./Login";
import Clubs from "./paginas/Clubs"; // Ejemplo de componente
import Ejecuciones from "./Ejecuciones"; // Ejemplo de componente
import Presupuestos from "./Presupuestos"; // Ejemplo de componente
import Usuarios from "./Usuarios";
import "./App.css";
import MiEquipo from "./paginas/miClub";
import MiPerfil from "./paginas/miPerfil";
import Contabilidad from "./paginas/Contabilidad";
import routesConfig from "./Auxiliares/routesConfig";

// Map de componentes para rutas dinámicas
const componentsMap = {
  Dashboard,
  Clubs,
  Ejecuciones,
  Presupuestos,
  Usuarios,
  MiEquipo,
  MiPerfil,
  Contabilidad,
  // Login,
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route element={<Layout />}>
          {routesConfig.map((route) => {
            const Component = componentsMap[route.component];

            if (!route.roles) {
              // Ruta pública
              return (
                <Route >
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<Component />}
                  />
                </Route>
              );
            }

            // Ruta protegida
            return (
              <Route
                key={route.path}
                element={<ProtectedRoute allowedRoles={route.roles} />}
              >
                {/* <Route element={<Layout />}> */}
                <Route path={route.path} element={<Component />} />
                {/* </Route> */}
              </Route>
            );
          })}
        </Route>
        {/* Ruta fallback */}
        <Route path="*" element={<Dashboard />} />
      </Routes>
    </Router>
  );
};

export default App;
