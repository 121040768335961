import React, { useState,useEffect } from 'react';
import { TextField, Button, MenuItem, Box, Typography } from '@mui/material';
import api from '../../servicios/apiService';
import CargandoDatos from '../../Auxiliares/CargandoDatos';
const MovimientosFormulario = ({ clubId, onMovementAdded }) => {
  const [formData, setFormData] = useState({
    tipo: 'ingreso',
    fecha: '',
    concepto: '',
    importe: '',
    equipo_id:'',
  });
  const [equipos, setEquipos] = useState([]); // Estado para almacenar los equipos
  const [loading, setLoading] = useState(false); // Estado de carga
  // Fetch de los equipos cuando el componente se monta
  useEffect(() => {
    const fetchEquipos = async () => {
      setLoading(true); // Al finalizar, cambiamos el estado a no cargando
      try {
        const response = await api.get(`/club/equipos`);
        
        console.log("🚀 ~ fetchEquipos ~ response:", response)
        setEquipos(response.data); // Almacena los equipos en el estado
        setLoading(false); // Al finalizar, cambiamos el estado a no cargando
      } catch (error) {
        console.error("Error al obtener los equipos", error);
        alert('Hubo un error al cargar los equipos');
        setLoading(false); // Al finalizar, cambiamos el estado a no cargando
      }
    };

    fetchEquipos();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post(`/movimientos`,formData);
      
      console.log("🚀 ~ handleSubmit ~ response:", response)
      onMovementAdded(); // Notifica al padre que se añadió un movimiento
      // alert('Movimiento añadido correctamente');
      setFormData({ tipo: 'ingreso', fecha: '', concepto: '', importe: '' });
    } catch (error) {
      console.error(error);
      // alert('Hubo un error al guardar el movimiento');
    }
  };
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CargandoDatos cargando={loading} />
      </Box>
    );
  }
  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h5" gutterBottom>
        Registrar Movimiento
      </Typography>
      <TextField
        select
        label="Tipo"
        name="tipo"
        value={formData.tipo}
        onChange={handleChange}
        fullWidth
        margin="normal"
      >
        <MenuItem value="ingreso">Ingreso</MenuItem>
        <MenuItem value="gasto">Gasto</MenuItem>
      </TextField>
      <TextField
        label="Fecha"
        name="fecha"
        type="date"
        value={formData.fecha}
        onChange={handleChange}
        fullWidth
        margin="normal"
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Concepto"
        name="concepto"
        value={formData.concepto}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Importe"
        name="importe"
        type="number"
        value={formData.importe}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
            {/* Añadimos el campo Select para los equipos */}
            <TextField
        select
        label="Equipo"
        name="equipo_id"
        value={formData.equipo_id}
        onChange={handleChange}
        fullWidth
        margin="normal"
      >
        {equipos.map((equipo) => (
          <MenuItem key={equipo.id} value={equipo.id}>
            {equipo.nombre} {/* Asegúrate de que 'nombre' sea el campo correcto */}
          </MenuItem>
        ))}
      </TextField>
      <Button type="submit" variant="contained" color="primary" fullWidth>
        Añadir Movimiento
      </Button>
    </Box>
  );
};

export default MovimientosFormulario;
